:root,
html {
    --background-color: #262626;
    --foreground-color: #4b7c41;
    /* font */
    font-family: sans-serif, Helvetica, arial;

    /* color */
    background-color: var(--background-color);
    color: white;
}


/* ---- a ---- */
a {
    color: var(--background-color);
    transition: 0.5s;
}
a:hover { color: #646464 }


/* ---- header ---- */
header {
    /* background */
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../img/Producten.jpg");

    /* size & positioning */
    height: 400pt;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

header div {
    position: relative;
    top: 50%;
}

header div h1 {
    font-size: 300%;
    margin-top: 0;
}


/* ---- products ---- */
.products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500pt, 1fr));
}

.products article {
    /* colors */
    color: var(--background-color);
    background-color: var(--foreground-color);

    /* box model */
    margin: 10pt;
    padding: 10pt;
    border-radius: 20pt;

    /* grid */
    display: grid;
    grid-template-columns: fit-content(20%) fit-content(70%);
}
    

article div {
    margin: 10pt;
    align-items: center;
}

.products article img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

article h2, article h3, article h4 { margin-bottom: 0}

article p { margin-top: 3pt }

/* ---- products-small ---- */
.products-small article {
    /* colors */
    color: var(--background-color);
    background-color: var(--foreground-color);

    /* box model */
    margin: 10pt;
    padding: 10pt;

    border-radius: 20pt;

    max-width: fit-content;

    display: inline-flex;
}

.products-small img { height: 150pt }

/* ---- awards ---- */
.awards { 
    background-color: var(--background-color);

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500pt, 1fr));
}

.awards article {
    /* colors */
    color: var(--background-color);
    background-color: white;

    padding: 10pt;
    margin: 10pt;

    border-radius: 20pt;

    max-width: fit-content;

    margin-left: auto;
    margin-right: auto;
}

.awards img {
    width: 100%;
    max-height: 500pt;
    object-fit: contain;
}

/* ---- footer ---- */
footer {
    background-color: #608548;
    color: white;

    padding: 10pt;
    padding-top: 5pt;

    margin-top: 10pt;
}

footer a { color: darkgray }
footer a:visited { color: darkgray }
footer a:hover { color: lightgray}

.author { margin-bottom: 0 }

/* small screen */
@media screen and (max-width: 900px) {
    .products {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .products article {
        max-width: 100%;
        max-height: 100%;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .products img {
        height: 50%;
    }

    .awards {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .awards img {
        max-height: 100%;
    }
}
